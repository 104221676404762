import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { DateCard } from 'components/DateCard';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import { useCart } from 'shared/CartContext';
import { SeatSelectionModal } from './SeatSelectionModal';
import { TimerCountdown } from './TimerCountdown';

const HeaderContainer = styled.div`
  background-color: rgb(0, 0, 31);
  color: white;
`;

const Header = styled.div`
  background: #1a1a47;
  padding: 10px;
  justify-content: start;
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: 60px;
`;

const EventName = styled.span`
  //styleName: PP - H3;
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
`;

const EventTrack = styled.span`
  //styleName: PP - Body;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #adadad;
`;

const FooterContainer = styled.div`
  height: 140px;
  background-color: #fa4616;
  display: flex;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: white;
  flex: 1;

  @media (max-width: 536px) {
    paddingright: 5px;
  }
`;

const ItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Count = styled.div`
  background-color: white;
  border-radius: 50%;
  color: black;
  padding: 5px;
  width: 15px;
  height: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const CART_TIMEOUT = 600000;

export default function SeatsManagerModal({ isVisible, setIsVisible, event }) {
  const {
    total,
    selectedSeatObject,
    ticketCount,
    seatId,
    addTicketSeat,
    removeTicketSeat,
    chartRef,
    clearTicket,
    tickets,
    selected,
  } = useCart();

  const [seatSelectionIsVisible, setSeatSelectionIsVisible] = useState(false);

  const [timer, setTimer] = useState(CART_TIMEOUT);

  const seatCategory = tickets[selected]?.tickets
    ?.filter(
      (ticket) => ticket.reserved_seat && ticket.ticket_tiers?.length > 0
    )
    ?.map((ticket) => {
      const ticketTypes = ticket.ticket_tiers.map((tier) => ({
        ticketType: tier.ticketType,
        label: tier.label,
        price: currency.format(
          tier.track_price ? tier.track_price : tier.price
        ),
        id: tier.id,
      }));
      return {
        category: ticket.reserved_seat_category,
        ticketTypes,
      };
    });

  const seatsio = JSON.parse(sessionStorage.getItem('seatsio'));
  const holdToken = seatsio?.holdToken;

  const theme = useTheme();

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 860px)' });
  const isMobilesm = useMediaQuery({ query: '(max-width: 536px)' });

  const addSeat = (seat) => {
    addTicketSeat(seat);
  };

  const removeSeat = (seat) => {
    removeTicketSeat(seat);
  };

  const onClose = () => {
    if (chartRef.current?.chart) chartRef.current.chart.resetView();

    // Add a delay of 1 second before closing the modal
    setTimeout(() => {
      setIsVisible(false);
    }, 1000);
  };

  const onHoldSucceeded = async (seats) => {
    const seat = seats[seats.length - 1];

    // Get the selected id from seatCategory based on the selected ticket type
    const selectedTicketType = seatCategory
      ?.find((category) => category.category === seat.category.label)
      ?.ticketTypes.find((type) => type.ticketType === seat.selectedTicketType);

    addSeat({
      id: seat.id,
      labels: seat.labels,
      category: seat.category,
      type: selectedTicketType?.id,
      price: selectedTicketType?.price,
    });
  };

  const onReleaseHoldSucceeded = (seats) => {
    const seat = seats[seats.length - 1];
    removeSeat({
      id: seat.id,
      labels: seat.labels,
      category: seat.category,
    });
  };

  const onHoldTokenExpired = () => {
    clearTicket();
  };

  let item = null;
  if (event?.start_date) {
    item = {
      date: moment(event?.start_date, 'MM-DD-YYYY').format('MMM D'),
      day: moment(event?.start_date, 'MM-DD-YYYY').format('ddd'),
      month: moment(event?.start_date, 'MM-DD-YYYY').format('MMM'),
      end_date: moment(event?.end_date, 'MM-DD-YYYY').format('MM-DD-YYYY'),
      isMultiDay: event?.isMultiDay ?? true,
      listDates:
        moment(event?.start_date, 'MM-DD-YYYY').format('DD') +
        ' - ' +
        (event?.end_date
          ? moment(event?.end_date, 'MM-DD-YYYY').format('DD')
          : ''),
    };
  }

  useEffect(() => {
    let interval;
    const timer = () => {
      const seatsio = JSON.parse(sessionStorage.getItem('seatsio'));
      const holdToken = seatsio?.holdToken;

      if (!holdToken) return;

      const storedTimer = localStorage.getItem(`timer - ${holdToken}`);
      const currentTime = new Date().getTime();
      let expiryTime;

      if (storedTimer) {
        expiryTime = parseInt(storedTimer, 10);
      } else {
        expiryTime = currentTime + CART_TIMEOUT; // 10 minutes from now
        // Remove any old timer keys
        Object.keys(localStorage).forEach((key) => {
          if (key.includes('timer')) localStorage.removeItem(key);
        });
        localStorage.setItem(`timer - ${holdToken}`, expiryTime);
      }

      interval = setInterval(() => {
        const timeLeft = expiryTime - new Date().getTime();
        if (timeLeft <= 0) {
          clearInterval(interval);
          setIsVisible(false);
          setTimer(CART_TIMEOUT);
          clearTicket();
        } else {
          setTimer(timeLeft);
        }
      }, 1000);
    };

    if (isVisible) {
      timer();
    }

    return () => clearInterval(interval);
  }, [isVisible, setIsVisible, setTimer, clearTicket]);

  const selectedSeatId = localStorage.getItem('selectedTicket');
  const seat_ids = tickets[selected]?.tickets?.find(
    (ticket) => ticket.id === +selectedSeatId
  )?.seat_ids ?? [seatId];

  return (
    <Modal
      maxWidth={600}
      overlayStyle={{
        marginLeft: isMobile ? 0 : isTab ? 50 : 200,
        zIndex: 10,
      }}
      modalStyle={{
        height: '100vh',
        maxHeight: '100vh',
        padding: 0,
        right: 0,
        top: 0,
        maxWidth: isMobile
          ? '100vw'
          : isTab
          ? 'calc(100vw - 50px)'
          : 'calc(100vw - 200px)',
        margin: '0',
        marginBottom: 10,
        borderRadius: 0,
        width: isMobile ? '100vw' : '100%',
        overflowY: 'auto', // Allow vertical scrolling if content overflows
      }}
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      noHeader={true}
    >
      <HeaderContainer>
        <Header>
          <div style={{ minWidth: 100 }}>
            {item !== null && (
              <DateCard
                item={item}
                margin={'margin: 0 10px 0 0'}
                horizontal={!isMobile ? true : false}
                isCartList={!isMobile ? true : false}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
            }}
          >
            <EventName>{event?.name}</EventName>
            <EventTrack>
              <Icon
                icon="location"
                size={16}
                color={'#adadad'}
                padding={'0 5px 0 0'}
              />
              {event?.track.name ?? event?.series.name}
            </EventTrack>
          </div>
        </Header>
      </HeaderContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginBottom: -35,
          marginTop: 10,
        }}
      >
        <div
          style={{
            transform: 'rotate(45deg)',
            display: 'flex',
            justifyContent: 'end',
            marginRight: 20,
            zIndex: 1000,
          }}
        >
          <Icon
            icon="plus"
            color={theme.colors.primary}
            size={24}
            onClick={onClose}
            style={{
              transform: 'rotate(45deg)',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      <div
        style={{
          height: isMobilesm
            ? 'calc(100vh - 180px)'
            : isTab
            ? 'calc(100vh - 150px)'
            : 'calc(100vh - 75px)', // Adjusted height for better fit
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto', // Ensures scrolling
        }}
      >
        {seat_ids && seat_ids.length > 0 && (
          <SeatsioSeatingChart
            ref={chartRef}
            workspaceKey={process.env.REACT_APP_SEAT_WORKSPACE_KEY}
            events={seat_ids ?? ''}
            region="na"
            session={holdToken ? 'manual' : 'start'}
            onHoldSucceeded={onHoldSucceeded}
            onReleaseHoldSucceeded={onReleaseHoldSucceeded}
            onHoldFailed={onHoldTokenExpired}
            holdOnSelectForGAs={true}
            holdToken={holdToken ? holdToken : ''}
            pricing={seatCategory}
          />
        )}
        <FooterContainer>
          <Footer>
            <ItemPrice>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Count>{ticketCount}</Count>
                Selected
              </div>
              <div style={{ fontSize: 32 }}>{`${
                ticketCount ? total ?? '$0.00' : '$0.00'
              }`}</div>
            </ItemPrice>

            <div
              style={{
                maxHeight: 80,
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                alignItems: 'center',
              }}
            >
              <TimerCountdown timer={timer} />
              <Button
                buttonStyle={{
                  backgroundColor: 'white',
                  borderRadius: 8,
                  flex: 1,
                  height: 80,
                }}
                textColor={'#fa4616'}
                onClick={() => setSeatSelectionIsVisible(true)}
              >
                <div
                  style={{
                    fontSize: isMobile ? '20px' : '24px',
                    fontWeight: 700,
                    lineHeight: isMobile ? '24px' : '34px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  Add to Cart
                  <div style={{ transform: 'rotate(-90deg)', width: 22 }}>
                    <Icon size={22} icon="chevron" color="#fa4616" />
                  </div>
                </div>
              </Button>
            </div>
          </Footer>
        </FooterContainer>

        <SeatSelectionModal
          isVisible={seatSelectionIsVisible}
          setIsVisible={setSeatSelectionIsVisible}
          selectedSeatObject={selectedSeatObject}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
}
